<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="日期" required>
        <el-date-picker
          v-model="time"
          class="el-popper DatepickerTime"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :disabled="disabled"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="供应商" prop="orgId">
        <el-select
          v-model="dataForm.orgId"
          @change="getPeriodList()"
          filterable
          :disabled="disabled"
        >
          <el-option
            v-for="(item, index) in orgList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="时段" prop="periodId">
        <el-select
          v-model="dataForm.periodId"
          :disabled="disabled"
          filterable
          clearable
          multiple
        >
          <el-option
            v-for="(item, index) in periodList"
            :key="index"
            :label="item.periodWithTimeName"
            :value="item.periodId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input
          v-model="dataForm.remarks"
          :disabled="disabled"
          placeholder="备注"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getOrgList } from '@/utils/options.js';
import { keys, pick } from 'lodash';
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      orgList: [],
      periodList: [],
      dataForm: {
        id: 0,
        periodId: [],
        specialStartDay: '',
        specialEndDay: '',
        orgId: '',
        remarks: '',
      },
      time: [],
      dataRule: {
        specialStartDay: [
          { required: true, message: '开始日期不能为空', trigger: 'blur' },
        ],
        specialEndDay: [
          { required: true, message: '结束日期不能为空', trigger: 'blur' },
        ],
        orgId: [{ required: true, message: '供应商不能为空', trigger: 'blur' }],
        periodId: [
          { required: true, message: '时段不能为空', trigger: 'blur' },
        ],
      },
    };
  },

  created() {
    this.getOrgList();
  },

  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.time = [];
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/cc/orgSpecialDay/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.orgSpecialDay, keys(this.dataForm));
              this.time = [
                data.orgSpecialDay.specialStartDay,
                data.orgSpecialDay.specialEndDay,
              ];
              this.dataForm.periodId = data.orgSpecialDay.periodIds
                .split(',')
                .map((item) => parseInt(item));
              this.getPeriodList();
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      if (this.time?.length) {
        this.dataForm.specialStartDay = this.time[0];
        this.dataForm.specialEndDay = this.time[1];
      }
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/cc/orgSpecialDay/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              ...this.dataForm,
              periodIds: this.dataForm.periodId.join(','),
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },

    //获得供应商下拉框
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    getPeriodList() {
      if (this.dataForm.orgId) {
        this.$http({
          url: `/tc/orgperiod/combo/${this.dataForm.orgId}`,
          method: 'get',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.periodList = data.list;
          }
        });
      }
    },
  },
};
</script>
